// Configure the Stripe checkout.js using data from the page itself
function configure_stripe(){

  // Default is for the form to be disabled with a Javascript warning,
  // since the Stripe checkout.js method obviously depends on JS.
  $('.javascript_warning').remove();
  $('#stripe_checkout').prop( "disabled", false );

  // Config data is grabbed from the page itself.
  // token: function() supplies a method that will be called when the payment has been made.
  // In our case we use this to update some existing form fields, which are already appropriate to
  // our application's structure, and then resubmit the form which was initially intercepted to
  // create the Stripe call.
  var handler = StripeCheckout.configure({
    key: $('.stripe_js_objects').data('config').publishable_key,
    image: $('.stripe_js_objects').data('config').image,
    locale: 'auto',
    shippingAddress: true,
    panelLabel: 'Pay {{amount}}',
    token: function(token) {

      // Since our address structure is inherited from Paypal, it doesn't perfectly match the Stripe
      // structure so needs a bit of pre-processing to sort it out
      if (token.card.name) {
        names = token.card.name.split(' ');
        first_name = names[0];
        last_name = names[1];
      }

      if( token.card.address_line2 ){
	address_street = token.card.address_line1 + ', ' + token.card.address_line2;
      } else {
	address_street = token.card.address_line1;
      }

      // Update the relevant form elements
      $('#payment_stripe_token').val(token.id);

      $('#user_email').val(token.email);
      $('#user_first_name').val(first_name);
      $('#user_last_name').val(last_name);
      $('#user_address_name').val(token.card.name);
      $('#user_address_street').val(address_street);
      $('#user_address_city').val(token.card.address_city);
      $('#user_address_zip').val(token.card.address_zip);
      $('#user_address_state').val(token.card.address_state);
      $('#user_address_country').val(token.card.address_country);

      $('#new_payment').submit();
    }
  });

  $('#stripe_checkout').on('click', function(e) {
	handler.open({
		name: 'Endnotes',
		description: $('.stripe_js_objects').data('order').description,
		currency: "gbp",
		amount: $('.stripe_js_objects').data('order').amount
	});
	// This prevents the basic HTML form submit from following through. For this reason we have to effectively
	// restart it when the payment is complete, using the $('#new_payment').submit() in the handler token function
	e.preventDefault();
  });

  $(window).on('popstate', function() {
	handler.close();
  });
}
