const baseElementStyles = {
  color: "#32325D",
  fontWeight: 500,
  fontFamily: "Open Sans, Segoe UI, sans-serif",
  fontSize: "16px",
  fontSmoothing: "antialiased",
  "::placeholder": { color: "#CFD7DF" },
  ":-webkit-autofill": { color: "#e39f48" }
}

const invalidFormStyles = {
  color: "#E25950",
  "::placeholder": { color: "#FFCCA5" }
}

const elementCssClasses = {
  focus: "focused",
  empty: "empty",
  invalid: "invalid"
}

const stripeElementOptions = {
  styles: { base: baseElementStyles, invalid: invalidFormStyles },
  classes: elementCssClasses
}

document.addEventListener("turbolinks:load", () => {
  const stripeTag = document.querySelector("#stripe-v3-js")
  const stripeForms = document.querySelectorAll(".js-stripe-form")

  if (stripeForms.length === 0) return
  if (!stripeTag) return

  const stripe = Stripe(stripeTag.dataset.key)
  const elementsObj = stripe.elements({
    fonts: [{ cssSrc: "https://fonts.googleapis.com/css?family=Open+Sans" }]
  })

  stripeForms.forEach((form) => {
    form.querySelector(".stripe-submit").disabled = false

    const successMessage = document.getElementById("success-message").content.cloneNode(true)

    const cardNumber = createStripeElement(elementsObj, "cardNumber", stripeElementOptions, form)
    createStripeElement(elementsObj, "cardExpiry", stripeElementOptions, form)
    createStripeElement(elementsObj, "cardCvc", stripeElementOptions, form)

    // Handle form submission
    form.addEventListener("submit", (e) => {
      e.preventDefault()
      e.stopPropagation()

      const secret = form.querySelector("#card-button").dataset.secret
      stripe.confirmCardPayment(secret, {
        payment_method: {
          card: cardNumber
        }
      }).then((result) => {
        if (result.error) {
          showErrors(form, result.error.message)
        } else {
          document.querySelector(".flash-messages").append(successMessage)
          form.parentNode.replaceChild(successMessage, form)
          location.reload(true)
        }
      })
    })
  })

  function createStripeElement (stripeElementsObj, name, options, form) {
    const stripeElement = stripeElementsObj.create(name, {
      style: options["styles"],
      classes: options["classes"]
    })
    stripeElement.mount("#" + name)
    handleErrors(stripeElement, form)
    return stripeElement
  }

  function handleErrors (stripeElement, form) {
    // Handle real-time validation errors from the card Element.
    stripeElement.addEventListener("change", (e) => {
      if (e.error) {
        showErrors(form, e.error.message)
      } else {
        clearErrors(form)
      }
    })
  }

  function showErrors (form, message) {
    form.querySelector(".card-errors").style.display = "block"
    form.querySelector(".error-text").textContent = message
  }

  function clearErrors (form) {
    form.querySelector(".card-errors").style.display = "none"
    form.querySelector(".error-text").textContent = ""
  }
})
