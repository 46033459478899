$(document).on('ready turbolinks:load', function() {
    
$ref = $("a.note_reference");
$bod = $("body").width();
$win = $( window ).width();
$smallscreen = 760;

$("ol#notes li").addClass("js-enabled");

$(".side-nav a").click(function(event) {
  event.preventDefault();
  $id = $(this).attr('href');
  $h2id = "h2" + $id;
  $h2pos = $($h2id).position().top;
  $('html, body').animate({scrollTop: $h2pos + 60});   
  //$('html, body').animate({scrollTop: $h2pos });  // for btm nav version
});

$ref.click(function(event) {

  // Stop the default event for link clicks, which will otherwise jump us to the note's position
  event.preventDefault();

  // Figure out the note number from the link and the selector for the note itself
  $note_number = this.href.match(/[0-9]+$/)[0];
  $note = "li#note_" + $note_number;

  $($note).css({
    "display":"block", 
    "top": $(this).position().top,
  });
  
  // Close other notes
  $($note).siblings().hide();

  // Position notes either side of ref on small screens
  if ($win < $smallscreen) {
    $refX = $(this).position().left;             // (this) is the note ref
    if ($refX < ($bod/2)) {                      // if note ref is on 1st half of screen
      $($note).css({
        "margin":"-2rem 1.5rem 0 " + ($refX + 16) + "px",
        "padding-right": "3rem" 
      });
    } else {                                     // if note ref is on 2nd half of screen
      $($note).css({
        "margin":"-2rem " + ($bod - $refX) + "px  0 -2rem",
        "left": 0, "right": 0, "padding-right": "2rem" 
      });
    }
  } else {                                      // give note max-width to x-position of ref
    $($note).css({"max-width": ( $bod - $(this).position().left ) + "px"});
  }

});

$close = $("ol#notes li");

$close.click(function() {
  $(this).hide();
});


// Left side border of blockquotes (CSS will not keep flush with x-height)
$('blockquote').each(function() {
  $h = $(this).height();
  $(this).css({'background-size': 1 + 'px ' + ($h - 16) + 'px'});
});

});
