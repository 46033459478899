$(document).on('ready turbolinks:load', function() {

// vars
$yimg = $("body.issue .yokai");
$yimgi = $("body.issues .yokai");
$yiml = $(".yokai-large");
// Background elements to dim when image is large:
$bg = $("#navigation, .details, .contents, .issue_title, .other_issues"); 
$bq = $(".back-quote"); 
i = 0; // boolian switches
j = 0; // to toggle states

// Set issues index issue title box to same height as yokai image
// (setTimeout is required to give Safari time to load the element)
//setTimeout(function(){ $(".issue_title").css({'height': ($yimgi.height()) + 'px'}); }, 500);

//PEEKABOO YOKAI IMAGES

function peekaboo_reset() {
  $yimg.removeClass("beebo");
  $bq.fadeOut(200);
  setTimeout(function(){ 
    //$yiml.removeClass("start"); 
    $bg.removeClass("dimwit");
  }, 200);
};

function peekaboo(){
  if (!i) {
    $yimg.addClass("beebo");
    //$yiml.addClass("start");
    $bg.addClass("dimwit");
    setTimeout(function(){ 
      $doc = $( document );
      $docW = $doc.width();
      $content_position = $(".contents").position();
      $content_L = $content_position.left;
      $bq.css({"width": ( $docW - $yimg.width() - $content_L - 10 ) + "px",
               "margin-left": $content_L + "px"
      });
      //if ( $docW > 700 ) {
      $bq.fadeIn(200);
      //};
    }, 500);
    i ++;
  } else {
    peekaboo_reset();
    i --;
  };
};


$yimg.click(peekaboo);
$(document).scroll(peekaboo_reset);
$(window).resize(peekaboo_reset);

// BIGUP YOKAI IMAGES
//$yiml.click(function() {
  //if (!j) { // Toggle full-screen image
    //$yiml.removeClass("start");
    //$yiml.addClass("finish");
    //$bg.addClass("dimwit");
    //setTimeout(function(){ 
      //$doc = $( document );
      //$docW = $doc.width();
      //$bq.css({"width": ( $docW - $yiml.width() ) - 8 + "px"});
      //if ( $docW > 700 ) {
        //$bq.fadeIn(200);
      //};
    //}, 500);
    //j ++;
  //} else { // reset / shrink image
    //$bq.fadeOut(200);
    //$yiml.removeClass("finish");
    //$yiml.addClass("start");
    //setTimeout(function(){ 
      //$yiml.removeClass("start"); 
      //$bg.removeClass("dimwit");
    //}, 200);
    //j --;
  //};
//});

});
