$(document).on('ready turbolinks:load', function() {

$win = $( window ).width();
$logo = $("body > .site-name, .details > .issue_title, .article > .issue_title, .article.translation .issue_title");
$end = $("body > .site-name span:first-child, .details > .issue_title span:first-child, .article > .issue_title span:first-child, .article.translation .issue_title span:first-child");
$notes = $("body > .site-name span:nth-child(2), .details > .issue_title span:nth-child(2), .article > .issue_title span:nth-child(2), .article.translation .issue_title span:nth-child(2)");
$nav = $("#navigation");
$artnav = $("body.article #navigation, body.about #navigation, body.translations #navigation");
$smallscreen = 640;

$(document).ready(minimise);
$(document).on('turbolinks:load', minimise);
$(window).resize(minimise);

function minimise() {
  $bodyW = $("body").width();
  $pageW = $( window ).width();
  $calcMargin = ( $pageW - $bodyW ) / 2;
  $logo.css({"right" : $calcMargin + "px"});
  $(".other_issues, .other_dossiers, .issue_title").css({"right" : $calcMargin + "px"});
  $(".issue_contents, .dossier_contents").add($(".side-nav")).css({"right" : $calcMargin + "px"});
  if ($win < $smallscreen) {
    // remove .article class as this is relevant only for larger screens
    $("body").removeClass("article");
  }
  //$contentsW = $(".contents").width();             // " 
  //$(".header").width($contentsW);                  // for btm nav version
  //$(".issues-grid").width($(".header").width());   //  "
}

// Minimise main menu & footer logo on scroll etc.
function minimiseSideNav() {
  $(".issue_contents, .other_issues, .dossier_contents, .other_dossiers").addClass("side-nav-min");
  $(".issue_contents li, .other_issues li, .dossier_contents li, .other_dossiers li").addClass("rule");
  $(".issue_contents a, .other_issues a, .dossier_contents a, .other_dossiers a").fadeOut(200);
};
function maximiseSideNav() {
  $(".issue_contents, .other_issues, .dossier_contents, .other_dossiers").removeClass("side-nav-min");
  $(".issue_contents li, .other_issues li, .dossier_contents li, .other_dossiers li").removeClass("rule");
  $(".issue_contents a, .other_issues a, .dossier_contents a, .other_dossiers a").fadeIn(200);
}
$( document ).scroll(function() {
  $docTop = $( document ).scrollTop();
  if ( $docTop > 50 ) {
    i = 0;
    $logo.addClass("logo-min");
    $end.addClass("end-min");
    $notes.addClass("notes-min");
    if ($win > $smallscreen) { 
      //$nav.addClass("min"); 
      $('.other_issues, .other_dossiers').addClass("min article-nav-min");
      //setTimeout(function(){ $('.header').addClass("min"); }, 200); // for btm nav version !
      $artnav.addClass("article-nav-min");
    };
    $nav.removeClass("show");
    $(".issue_contents, .dossier_contents, .dossier_posts").addClass("btm-adj");
    minimiseSideNav();
    $(".side-nav").addClass("fixie");
  } else { 
    i ++;
    $logo.removeClass("logo-min");
    $end.removeClass("end-min");
    $notes.removeClass("notes-min");
    minimiseSideNav();
    if ($win > $smallscreen) { 
      //$nav.removeClass("min"); 
      $('.other_issues, .other_dossiers').removeClass("min article-nav-min");
      //setTimeout(function(){ $('.header').removeClass("min"); }, 200);// for btm nav version !
      $artnav.removeClass("article-nav-min");
    };
    $(".issue_contents, .dossier_contents, .dossier_posts").removeClass("btm-adj");
    $(".side-nav").removeClass("fixie");
  };
  //$sideNavTop = $(".side-nav").position.top;
  //if ($sideNavTop < 100) {
  //};
});

// Pop up minimised side-menu on mouseover or click (touchscreens)
$("ol.issue_contents, ol.other_issues, ol.dossier_contents, ol.other_dossiers").on('mouseover click', function() {
  //$(".issue_contents, .other_issues").addClass("btm-adj");
  maximiseSideNav();
});
// Re-minimise side-menu on mouseleave
$("ol.issue_contents, ol.other_issues, ol.dossier_contents, ol.other_dossiers").mouseleave(function() {
  minimiseSideNav();
});

// Small screen pop-down menu 
$("#navigation").click(function() {
if ($win < $smallscreen) {
  if (!i) { 
    $nav.addClass("show"); 
    i = 1;
  } else {
    $nav.removeClass("show");
    i --;
  }
}
});

$("body.article .header").mouseover(function() {
    //$artnav.removeClass("article-nav-min");
    //$(".header").removeClass("min");
});
$("body.article .header").mouseout(function() {
    //setTimeout(function(){ 
  //$(".header").addClass("min");
  //if ($win > $smallscreen) {
      //$artnav.addClass("article-nav-min");
  //}
    //},500);
});

});
