// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or any plugin's vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
//= require jquery
//= require jquery_ujs
//= require_tree .

function hideCursor() {
  var body = document.body, 
      screen = document.getElementById('screen');
  body.classList.add('hide_cursor');
  screen.classList.add('hide_cursor');
}
function showCursor() {
  var body = document.body,
      screen = document.getElementById('screen');
  body.classList.remove('hide_cursor');
  screen.classList.remove('hide_cursor');
}

addEventListener('scroll', hideCursor);
addEventListener('mousemove', showCursor);
